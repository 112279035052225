import { LayoutContext } from "context/LayoutProvider";
import { MapContext } from "context/MapProvider";
import { useMedia } from "hooks/useMedia";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import Header from "../Header/Header";
// import LogOut from "../../icons/LogOut";

interface ITwoPaneLayoutProps {
  children: React.ReactNode;
  rightPane: React.ReactNode;
}
export default function TwoPaneLayout(props: ITwoPaneLayoutProps) {
  const { mapState } = useContext(MapContext);
  const { map } = mapState;
  const { children, rightPane } = props;
  const { paneToShow, setPaneToShow } = useContext(LayoutContext);
  const location = useLocation();
  const shouldHidePane = useMedia(["(max-width: 767px)"], [true], false);
  const leftPaneHidden = shouldHidePane && paneToShow === "right";
  const rightPaneHidden = shouldHidePane && paneToShow === "left";
  const leftPaneClassNames = leftPaneHidden
    ? "col-12 col-md-6 shadow-lg h-100 px-3 bg-white d-none"
    : "col-12 col-md-6 shadow-lg h-100 px-3 bg-white";
  const rightPaneClassNames = rightPaneHidden
    ? "col-12 col-md-6 d-none"
    : "col-12 col-md-6";

  const guideLink = "https://confluence.sdfi.dk/x/RwLiCQ";

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.updateSize();
      }, 100);
      setTimeout(() => {
        const canvasEl = document.getElementsByTagName("canvas").length
          ? document.getElementsByTagName("canvas")[0]
          : undefined;
        if (canvasEl && !canvasEl.ariaLabel) {
          canvasEl.setAttribute("aria-label", "Interaktivt kort");
        }
      }, 3000);
    }
  }, [paneToShow, map]);

  return (
    <div className="h-100 w-100 d-flex row g-0">
      {shouldHidePane && (
        <button
          type="button"
          className="c-switch-map shadow btn btn-lg btn-primary rounded-circle"
          onClick={() => {
            if (paneToShow === "left") setPaneToShow("right");
            else setPaneToShow("left");
          }}
          aria-live="assertive"
          aria-label={
            paneToShow === "left" ? "Vis kort" : "Tilbage til Indhold"
          }
        >
          {paneToShow === "left" && (
            <svg
              viewBox="0 0 250 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="matrix(17.857142857142858,0,0,17.857142857142858,0,0)">
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                  d="M1.63819 12.7433C2.03129 12.6731 2.43418 12.5221 2.86393 12.3846C3.50614 12.1791 4.14822 11.9462 4.74101 11.7964C4.8881 11.7593 5.04194 11.762 5.18939 11.7977L9.09204 12.7434C9.74536 12.8864 10.3932 12.6163 11.1173 12.3846C11.5645 12.2415 12.0115 12.0852 12.4419 11.9528C12.8911 11.8147 13.2187 11.4085 13.2187 10.9386V2.58094C13.2187 2.11103 12.8855 1.70698 12.4174 1.74757C11.8969 1.79269 11.3403 1.92721 10.9436 2.02964C10.4706 2.15176 9.92689 2.40431 9.39833 2.64088C9.19999 2.72965 8.97781 2.74844 8.76663 2.69726L4.96532 1.77612C4.312 1.63314 3.30748 1.87025 2.69014 2.02964C2.30452 2.12921 1.87187 2.31548 1.43874 2.50911C1.07473 2.67183 0.838623 3.03423 0.838623 3.43296V11.9386C0.838623 12.4085 1.1756 12.8259 1.63819 12.7433Z"
                ></path>
                {/* <path fill="currentColor" opacity=".25" d="M1.63819 12.7433C2.03129 12.6731 2.43418 12.5221 2.86393 12.3846C3.50614 12.1791 4.14822 11.9462 4.74101 11.7964C4.8881 11.7593 5.04194 11.762 5.18939 11.7977L9.09204 12.7434C9.74536 12.8864 10.3932 12.6163 11.1173 12.3846C11.5645 12.2415 12.0115 12.0852 12.4419 11.9528C12.8911 11.8147 13.2187 11.4085 13.2187 10.9386V2.58094C13.2187 2.11103 12.8855 1.70698 12.4174 1.74757C11.8969 1.79269 11.3403 1.92721 10.9436 2.02964C10.4706 2.15176 9.92689 2.40431 9.39833 2.64088C9.19999 2.72965 8.97781 2.74844 8.76663 2.69726L4.96532 1.77612C4.312 1.63314 3.30748 1.87025 2.69014 2.02964C2.30452 2.12921 1.87187 2.31548 1.43874 2.50911C1.07473 2.67183 0.838623 3.03423 0.838623 3.43296V11.9386C0.838623 12.4085 1.1756 12.8259 1.63819 12.7433Z"></path> */}
                <path
                  fill="none"
                  stroke="currentColor"
                  d="M9.08524 12.7417L5.18937 11.7977C5.11574 11.7798 5.04052 11.7702 4.96533 11.7697V1.90305C4.96533 1.86088 4.96795 1.81912 4.97307 1.778L8.76661 2.69726C8.87377 2.72323 8.98376 2.73118 9.09203 2.72112V12.6239C9.09203 12.6635 9.08973 12.7029 9.08524 12.7417Z"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                ></path>
                <path
                  fill="currentColor"
                  opacity=".25"
                  d="M9.08524 12.7417L5.18937 11.7977C5.11574 11.7798 5.04052 11.7702 4.96533 11.7697V1.90305C4.96533 1.86088 4.96795 1.81912 4.97307 1.778L8.76661 2.69726C8.87377 2.72323 8.98376 2.73118 9.09203 2.72112V12.6239C9.09203 12.6635 9.08973 12.7029 9.08524 12.7417Z"
                ></path>
              </g>
            </svg>
          )}

          {paneToShow === "right" && (
            <svg
              viewBox="0 0 250 250"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="matrix(17.857142857142858,0,0,17.857142857142858,0,0)">
                <path
                  d="M0.882986 4.5L9.11701 4.5C10.1779 4.5 11.1953 4.92143 11.9454 5.67157C12.6956 6.42172 13.117 7.43913 13.117 8.5C13.117 9.56087 12.6956 10.5783 11.9454 11.3284C11.1953 12.0786 10.1779 12.5 9.11701 12.5H4.11701"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M3.58948 1.79349C2.40201 2.7664 1.80865 3.35963 0.882975 4.49999C1.80865 5.64035 2.402 6.23359 3.58948 7.20649"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </g>
            </svg>
          )}
        </button>
      )}

      <div
        className={leftPaneClassNames}
        style={{ zIndex: 20, overflowY: "scroll" }}
      >
        {/* Content */}
        <a
          className="c-skip-to-content btn btn-dark visually-hidden-focusable shadow"
          href="#main-content"
        >
          Sæt fokus til primært indhold
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-caret-right-fill ms-2"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          </svg>
        </a>
        <Header />
        <div id="main-content" className="container pe-xl-5 me-xl-0">
          {/* aria-live="polite" - Vi skal nok finde en anden måde at announce siderne på */}
          {children}

          <footer className="w-100 py-4 mt-5 text-center">
            <p className="small mb-2">
              © 2024 Klimadatastyrelsen
            </p>
            <p className="small">
              © FIP er udviklet af{" "}
              <a
                className="text-dark"
                href="https://septima.dk"
                target="_blank"
                rel="noreferrer"
              >
                {/* Septima */}
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  width="243.598"
                  height="60.027"
                  viewBox="0 0 243.598 60.027"
                  aria-label="Septima P/S"
                  style={{
                    height: "16px",
                    width: "auto",
                    marginTop: "-2px",
                    marginLeft: "2px",
                  }}
                >
                  <path
                    data-name="Path 867"
                    d="M0,0V26.243H21.716a6.08,6.08,0,0,1,1.036-2.076l-4.211-3.88.678-.735,4.212,3.881a6.073,6.073,0,0,1,2.812-1.465V0H0Z"
                  ></path>
                  <path
                    data-name="Path 868"
                    d="M0,29.184V55.426H26.243V33.8a6.056,6.056,0,0,1-2.433-1.128l-3.88,4.211-.737-.678L23.074,32a6.061,6.061,0,0,1-1.463-2.812H0Z"
                  ></path>
                  <path
                    data-name="Path 869"
                    d="M29,0V22.025a6.076,6.076,0,0,1,2.258,1.085l3.88-4.21.735.676-3.881,4.212a6.091,6.091,0,0,1,1.377,2.455H55.246V0H29Z"
                  ></path>
                  <path
                    data-name="Path 870"
                    d="M57.638,38.377a21.5,21.5,0,0,0-10.41-2.455,16.162,16.162,0,0,0-7.142,1.257,3.9,3.9,0,0,0-2.472,3.566A3.33,3.33,0,0,0,40,43.983a23.4,23.4,0,0,0,7.159,1.069q4.323.174,5.845.346a14.98,14.98,0,0,1,4.426,1A5.628,5.628,0,0,1,61.1,51.969,6.671,6.671,0,0,1,57.759,57.9q-3.337,2.127-9.563,2.127-8.439,0-13.765-3.7l1.315-1.9a21.922,21.922,0,0,0,11.966,3.459q5.188,0,7.972-1.534a4.705,4.705,0,0,0,2.784-4.257,3.761,3.761,0,0,0-2.525-3.654q-2.249-1-7.643-1.243-3.978-.171-5.534-.345a15.781,15.781,0,0,1-4.115-.83,5.034,5.034,0,0,1-3.666-5.014A5.938,5.938,0,0,1,38.132,35.7q3.147-1.92,8.958-1.92,7.849,0,12.139,2.871l-1.591,1.729Z"
                  ></path>
                  <path
                    data-name="Path 871"
                    d="M94.262,59.44H69.949V34.4H93.294v2.143H72.2v8.542H86.861v2.145H72.2V57.3H94.262V59.44Z"
                  ></path>
                  <path
                    data-name="Path 872"
                    d="M120.512,47.024a5.2,5.2,0,0,0,3.856-1.375,5.271,5.271,0,0,0,1.367-3.883q0-5.22-6.053-5.221H104.05V47.024Zm.173-12.623a7.487,7.487,0,0,1,5.516,2.006,7.346,7.346,0,0,1,2.024,5.464,6.826,6.826,0,0,1-2.179,5.325,8.541,8.541,0,0,1-5.949,1.973H104.05V59.44H101.8V34.4h18.883Z"
                  ></path>
                  <path
                    data-name="Path 873"
                    d="M157.1,36.544H145.136v22.9h-2.247v-22.9H130.922V34.4H157.1v2.144Z"
                  ></path>
                  <rect
                    data-name="Rectangle 1591"
                    width="2.248"
                    height="25.039"
                    transform="translate(162.67 34.401)"
                  ></rect>
                  <path
                    data-name="Path 874"
                    d="M207.872,59.44h-2.248V36.579h-.139L191.617,54.424l-13.9-17.845h-.1V59.44h-2.248V34.4h3.505l12.748,16.7L204.42,34.4h3.452V59.44Z"
                  ></path>
                  <path
                    data-name="Path 875"
                    d="M236.468,50.793l-7.522-14.221h-.333l-7.563,14.22Zm7.13,8.646h-2.58l-3.421-6.5H219.915l-3.477,6.5h-2.583L227.1,34.4h3.32L243.6,59.439Z"
                  ></path>
                </svg>
              </a>
            </p>
            <p className="small">
              <a
                href="https://www.was.digst.dk/indberetning-sdfi-dk"
                target="_blank"
                rel="noreferrer"
              >
                Tilgængelighedserklæring
              </a>
              <span className="px-2" role="separator" aria-hidden="true">
                •
              </span>
              <a href={guideLink} target="_blank" rel="noreferrer">
                Hjælp
              </a>
            </p>
          </footer>
        </div>
      </div>
      <div className={rightPaneClassNames}>
        {/* Map */}
        {rightPane}
      </div>
    </div>
  );
}
